import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { useRecoilState } from 'recoil'

import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useProductsHook } from '../../hooks/useProductsHook'
import { mainSearchState } from '../../store/atoms'

import './style.scss'

const InputSearch = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useRecoilState(mainSearchState)
  const [showInput, setShowInput] = useState(!!search)
  const { fetchProducts } = useProductsHook()
  const isCatalog = location.pathname === '/catalog'
  const isRoot = location.pathname === '/'

  const onSubmit = () => {
    if (!search) {
      setShowInput(!showInput)
    }

    if (isCatalog) {
      fetchProducts()
    }
    if (isRoot && showInput) {
      navigate('/catalog')
    }
  }

  const onClear = () => {
    setSearch('')
    fetchProducts({ search: '' })
  }

  return (
    <div className="search-container">
      <div className={`input-wrapper ${showInput ? 'show' : ''}`}>
        <Input
          className="search-input text"
          type="text"
          placeholder="поиск по каталогу"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onPressEnter={onSubmit}
          allowClear
          onClear={onClear}
        />
      </div>
      <div
        className={`icon-wrapper ${showInput ? 'active' : ''}`}
        onClick={onSubmit}
      >
        <SearchOutlined className="icon" />
      </div>
    </div>
  )
}

export default InputSearch
