import React from 'react'
import { useNavigate } from 'react-router-dom'

import BreadCrumbs from '../bread-crumbs'
import CustomButton from '../button-custom'
import CatalogFilter from '../catalor-filter'
import HeaderMenu from '../header-menu'
import InputSearch from '../input-search'
import Logo from '../logo'

import './style.scss'

const Header = ({
  showFilters = false,
  showBreadcrumbs = false,
  showSearchIcon = true,
  isAbsolute = false,
  showBack = false,
}) => {
  const navigate = useNavigate()
  return (
    <header className="header">
      <div className={`default ${isAbsolute ? 'absolute' : ''}`}>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault()
            navigate('/')
          }}
          className="text nav-text"
        >
          <Logo />
        </a>

        <nav className="header-icons">
          {showSearchIcon && <InputSearch />}
          <HeaderMenu />
        </nav>
      </div>
      {(showFilters || showBreadcrumbs) && (
        <div className="default">
          {showBack && (
            <CustomButton
              text="выбор темы"
              className="btn"
              arrowDirection="left"
              onClick={() => navigate('/recommendations')}
            />
          )}
          {showFilters && <CatalogFilter />}
          {showBreadcrumbs && <BreadCrumbs />}
        </div>
      )}
    </header>
  )
}

export default Header
