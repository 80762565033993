import { Layout, Menu } from 'antd'

import React from 'react'
import { useNavigate } from 'react-router-dom'

const { Header, Content } = Layout

const AdminPanel = ({ children }) => {
  const navigate = useNavigate()

  const items = [
    {
      key: '/admin',
      label: `Мебель`,
    },
    {
      key: '/admin/categories',
      label: `Категории`,
    },
    {
      key: '/admin/materials',
      label: `Материалы`,
    },
    {
      key: '/admin/styles',
      label: `Стили`,
    },
    {
      key: '/admin/purposes',
      label: `Назначение`,
    },
  ]

  const handleMenuClick = (value) => {
    navigate(value.key)
  }

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['products']}
          items={items}
          onClick={handleMenuClick}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
      </Header>
      <Content
        style={{
          padding: '0 48px',
        }}
      >
        {children}
      </Content>
    </Layout>
  )
}

export default AdminPanel
