export const SORTING_OPTIONS = [
  {
    id: 1,
    name: 'Цена (возрастание)',
  },
  {
    id: 2,
    name: 'Цена (убывание)',
  },
  {
    id: 3,
    name: 'Новое',
  }
]

export const MAX_PRICE = 100000
export const MIN_PRICE = 500