import { atom } from 'recoil'

import { MAX_PRICE, MIN_PRICE } from '../constants/global'

export const productsState = atom({
  key: 'productsState',
  default: [],
})

export const productDetailsState = atom({
  key: 'productDetailsState',
  default: null,
})

export const recommendProductsState = atom({
  key: 'recommendProductsState',
  default: [],
})



export const filtersDataState = atom({
  key: 'filtersDataState',
  default: {
    categories: [],
    materials: [],
    purposes: [],
    styles: [],
  },
})

export const selectedSortingState = atom({
  key: 'selectedSortingState',
  default: 3,
})

export const selectedCategoriesState = atom({
  key: 'selectedCategoriesState',
  default: [],
})

export const selectedMaterialsState = atom({
  key: 'selectedMaterialsState',
  default: [],
})

export const selectedStylesState = atom({
  key: 'selectedStylesState',
  default: [],
})

export const selectedPurposesState = atom({
  key: 'selectedPurposesState',
  default: [],
})

export const selectedPricesState = atom({
  key: 'selectedPricesState',
  default: [MIN_PRICE, MAX_PRICE],
})

export const selectedDiscoutState = atom({
  key: 'selectedDiscoutState',
  default: false,
})

export const mainSearchState = atom({
  key: 'mainSearchState',
  default: '',
})
 
export const offsetState = atom({
  key: 'offsetState',
  default: 0,
})

export const totalProductsState = atom({
  key: 'totalProductsState',
  default: 0,
})
 