import React from 'react'

import ButtonModal from '../../components/button-modal'
import CustomCarousel from '../../components/custom-carusel'
import Header from '../../components/header'
import { mockImagesSliderAbout } from '../../data/catalogItem'

import './style.scss'

const About = () => {
  return (
    <div>
      <Header
        isAbsolute
        showSearchIcon={false}
      />
      <div className="designers container">
        <CustomCarousel
          className="designers-slider"
          infinite={false}
          hideTextUntilActive={true}
          slides={mockImagesSliderAbout}
          showPrevArrow={false}
        />
        <div className="designers-content">
          <ButtonModal
            className="designers-btn"
            text={'оставить заявку'}
          />
        </div>
      </div>
    </div>
  )
}

export default About
