import { Checkbox } from 'antd'

import React from 'react'

import './style.scss'

const CheckboxFilter = ({ selected = [], setSelected, options = [] }) => {
  return (
    <Checkbox.Group 
      value={selected}
      onChange={setSelected}
      className={'checkbox-wrapper'}
    >
      {options.filter(o => o.furnitureCount > 0).map((option) => (
        <Checkbox
          value={option.id}
          key={option.id}
        > 
          <span>{option.name}{' '}({option.furnitureCount})</span>
        </Checkbox>
      ))}
      
    </Checkbox.Group>
  )
}

export default CheckboxFilter
