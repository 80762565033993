import React from 'react'

import Header from '../../components/header'
import RecommendationCard from '../../components/recommendation-card'
import RecommendationTitle from '../../components/recommendation-title'
import {
  mockDataRecommendationCard,
  mockDataRecommendationCard2,
} from '../../data/catalogItem'

import './style.scss'

const Recommendations = () => {
  return (
    <div className="container recommendations">
      <Header
        showBack
        showBreadcrumbs
      />
      <div className="content">
        <RecommendationTitle />
        <RecommendationCard data={mockDataRecommendationCard} />
        <RecommendationCard
          data={mockDataRecommendationCard2}
          imagePosition={'right'}
        />
      </div>
    </div>
  )
}

export default Recommendations
