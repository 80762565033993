import { useRecoilState, useRecoilValue } from 'recoil'

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import CustomButton from '../../components/button-custom'
import Header from '../../components/header'
import LoadingSpinner from '../../components/loading-spinner'
import ProductCard from '../../components/product-card'
import InfoComp from '../../components/result'
import { PAGE_LIMIT, useProductsHook } from '../../hooks/useProductsHook'
import { offsetState } from '../../store/atoms'
import { totalProductsSelector } from '../../store/selector'

import './style.scss'

const Catalog = () => {
  const navigate = useNavigate()
  const { fetchProducts, products, productsLoading } = useProductsHook()
  const [offset, setOffset] = useRecoilState(offsetState)
  const total = useRecoilValue(totalProductsSelector)
  const maxPage = total && Math.ceil(total / PAGE_LIMIT)
  const [page, setPage] = useState(1)

  const handleProductClick = (id) => {
    navigate(`/catalog/${id}`)
  }

  useEffect(() => {
    fetchProducts({ offset })

    setPage(offset / PAGE_LIMIT + 1)
  }, [])

  const handleLeft = () => {
    if (page <= 1 && productsLoading) return
    window.scrollTo(0, 0)

    setPage(page - 1)
    const newOffset = offset - PAGE_LIMIT
    setOffset(newOffset)
    fetchProducts({ offset: newOffset })
  }

  const handleRight = () => {
    if (page + 1 > maxPage && productsLoading) return
    window.scrollTo(0, 0)

    setPage(page + 1)
    const newOffset = offset + PAGE_LIMIT
    setOffset(newOffset)
    fetchProducts({ offset: newOffset })
  }

  return (
    <div className="container catalog">
      <Header
        showFilters
        showBreadcrumbs
      />
      <section>
        <div className="content">
          {productsLoading && <LoadingSpinner />}
          {!products.length && !productsLoading && (
            <div className="no-data">
              <InfoComp text="Нет данных по вашему запросу" />
            </div>
          )}
          {!productsLoading && !!products.length && (
            <div className="product-grid">
              {products.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  onClick={() => handleProductClick(product.id)}
                />
              ))}
              {maxPage > 1 && (
                <div className="pagination">
                  {page > 1 && (
                    <CustomButton
                      className="catalog-filter-button"
                      arrowDirection={'left'}
                      text="назад"
                      onClick={handleLeft}
                    />
                  )}

                  <span className="page text">{page}</span>

                  {page < maxPage && (
                    <CustomButton
                      className="catalog-filter-button"
                      arrowDirection={'right'}
                      text="вперед"
                      onClick={handleRight}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

export default Catalog
