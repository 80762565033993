import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useDebounce from '../../hooks/useDebounce'
import {
  DEFAULT_PRODUCTS_PAYLOAD,
  useProductsHook,
} from '../../hooks/useProductsHook'
import CustomButton from '../button-custom'
import ButtonModal from '../button-modal'
import GalleryGrid from '../gallery'
import ProductTags from '../product-tags'
import RecommendedChairs from '../recommended-сhairs'

import './style.scss'

export const RECOMMEND_LIMIT = 3

const ProductRecommendation = ({ product }) => {
  const navigate = useNavigate()
  const { products, fetchProducts, productsLoading } = useProductsHook()
  const [selectedMaterials, setSelectedMaterials] = useState([])
  const [selectedStyles, setSelectedStyles] = useState([])
  const [selectedPurposes, setSelectedPurposes] = useState([])
  const [offset, setOffset] = useState(0)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768)

  const debouncedMaterials = useDebounce(selectedMaterials, 600)
  const debouncedStyles = useDebounce(selectedStyles, 600)
  const debouncedPurposes = useDebounce(selectedPurposes, 600)

  const isTagSelected =
    !!selectedMaterials.length ||
    !!selectedStyles.length ||
    !!selectedPurposes.length

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const materialIds = product.materials.map((it) => it.id)
    const purposeIds = product.purposes.map((it) => it.id)
    const styleIds = product.styles.map((it) => it.id)

    const payload = {
      ...DEFAULT_PRODUCTS_PAYLOAD,
      offset,
      limit: RECOMMEND_LIMIT,
      materialIds: !isTagSelected ? materialIds : debouncedMaterials,
      styleIds: !isTagSelected ? purposeIds : debouncedStyles,
      purposeIds: !isTagSelected ? styleIds : debouncedPurposes,
      exceptIds: [product.id],
    }

    fetchProducts(payload)
  }, [debouncedMaterials, debouncedStyles, debouncedPurposes, offset])

  const handleMaterials = (id) => {
    if (selectedMaterials.includes(id)) {
      const filtered = selectedMaterials.filter((it) => it !== id)
      setSelectedMaterials(filtered)
    } else {
      setSelectedMaterials([...selectedMaterials, id])
    }
  }

  const handleStyles = (id) => {
    if (selectedStyles.includes(id)) {
      const filtered = selectedStyles.filter((it) => it !== id)
      setSelectedStyles(filtered)
    } else {
      setSelectedStyles([...selectedStyles, id])
    }
  }

  const handlePurposes = (id) => {
    if (selectedPurposes.includes(id)) {
      const filtered = selectedPurposes.filter((it) => it !== id)
      setSelectedPurposes(filtered)
    } else {
      setSelectedPurposes([...selectedPurposes, id])
    }
  }

  const galleryImages = product.images.filter((i) => !i.isSmall)

  return (
    <div className="recommended-container">
      {isMobileView && (
        <RecommendedChairs
          products={products}
          offset={offset}
          fetching={productsLoading}
          setOffset={setOffset}
        />
      )}
      <div className="left-block">
        <h1 className="title">{product.name}</h1>
        <div className="price">{product.price}с</div>
        <ButtonModal className="catalog-detail-btn" />
        {product.description.length > 0 && (
          <div className="description">{product.description}</div>
        )}

        {product.materials.length > 0 && (
          <ProductTags
            title="материал"
            tags={product.materials}
            selected={selectedMaterials}
            setSelected={handleMaterials}
          />
        )}
        {product.styles.length > 0 && (
          <ProductTags
            title="стиль"
            tags={product.styles}
            selected={selectedStyles}
            setSelected={handleStyles}
          />
        )}
        {product.purposes.length > 0 && (
          <ProductTags
            title="назначение"
            tags={product.purposes}
            selected={selectedPurposes}
            setSelected={handlePurposes}
          />
        )}

        <CustomButton
          text="назад"
          className="catalog-detail-btn"
          arrowDirection="left"
          onClick={() => navigate('/catalog')}
        />
      </div>
      <div className="right-block">
        <GalleryGrid images={galleryImages} />
        {!isMobileView && (
          <RecommendedChairs
            products={products}
            offset={offset}
            fetching={productsLoading}
            setOffset={setOffset}
          />
        )}
      </div>
    </div>
  )
}

export default ProductRecommendation
