import { Flex } from 'antd'

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import LoadingSpinner from '../../../../components/loading-spinner'
import { useProductsHook } from '../../../../hooks/useProductsHook'

import ProductForm from './ProductForm'

const EditProduct = () => {
  const { id } = useParams()
  const { fetchProduct, productDetails, productLoading } = useProductsHook()

  useEffect(() => {
    fetchProduct(id)
  }, [id])

  return productLoading ? (
    <Flex
      style={{ minHeight: '300px' }}
      align="center"
      justify={'center'}
    >
      <LoadingSpinner />
    </Flex>
  ) : (
    !productLoading && productDetails && (
      <ProductForm product={productDetails} />
    )
  )
}

export default EditProduct
