import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'

import Icon from '../icon'
import Slide from '../slide'

import './style.scss'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const PrevArrow = ({ className, onClick, show }) => {
  if (!show) return null
  return (
    <div
      className={`${className} custom-prev-arrow`}
      onClick={onClick}
    >
      <Icon
        hover
        name="leftArrow"
        width={50}
        height={50}
      />
    </div>
  )
}

const NextArrow = ({ className, onClick, show }) => {
  if (!show) return null
  return (
    <div
      className={`${className} custom-next-arrow`}
      onClick={onClick}
    >
      <Icon
        hover
        name="rightArrow"
        width={50}
        height={50}
      />
    </div>
  )
}

const CustomCarousel = ({
  slides,
  infinite = true,
  showPrevArrow = true,
  showNextArrow = true,
  className,
  hideTextUntilActive = false,
  main = false,
}) => {
  const [slidesToShow, setSlidesToShow] = useState(1.1)
  const [showDots, setShowDots] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(1)
        setShowDots(true)
      } else {
        setSlidesToShow(1.1)
        setShowDots(false)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const settings = {
    infinite: infinite,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    speed: 500,
    dots: showDots,
    arrows: true,
    prevArrow: <PrevArrow show={showPrevArrow} />,
    nextArrow: (
      <NextArrow
        show={showNextArrow && (infinite || activeSlide < slides.length - 1)}
      />
    ),
    beforeChange: (current, next) => {
      const roundedNext = Math.round(next)
      setActiveSlide(roundedNext)
    },
  }
  const isMainClass = main ? 'custom-slider main-slider' : 'custom-slider'

  return (
    <Slider
      {...settings}
      className={isMainClass}
    >
      {slides.map((slide, index) => (
        <Slide
          key={index}
          className={className}
          image={slide.img}
          header={slide.header}
          number={slide.number}
          title={slide.title}
          description={slide.text}
          isActive={
            index === activeSlide ||
            (infinite && index === slides.length - 1 && activeSlide === 0)
          }
          hideTextUntilActive={hideTextUntilActive}
        />
      ))}
    </Slider>
  )
}

export default CustomCarousel
