import {
  CloseOutlined,
  InstagramOutlined,
  MenuOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import { Drawer } from 'antd'

import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Icon from '../icon'
import Logo from '../logo'

import './style.scss'

const HeaderMenu = () => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const navigate = useNavigate()
  const location = useLocation() // Хук для получения текущего пути

  const openDrawer = () => {
    setDrawerVisible(true)
  }

  const closeDrawer = () => {
    setDrawerVisible(false)
  }

  // Функция для определения активной ссылки
  const isActive = (path) => location.pathname === path

  return (
      <>
        <MenuOutlined
            className="icon"
            onClick={openDrawer}
        />
        <Drawer
            placement="top"
            closable={false}
            onClose={closeDrawer}
            open={drawerVisible}
            className="custom-drawer"
        >
          <div className="war">
            <div className="drawer-content">
              <div className="drawer-header">
                <Logo
                    desktopColor={'active'}
                    mobileColor={'mobile_active'}
                />
                <CloseOutlined
                    className="close-icon"
                    onClick={closeDrawer}
                />
              </div>
              <div className="drawer-nav">
                <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/')
                    }}
                    className={`title ${isActive('/') ? 'active' : ''}`} // Добавляем класс active, если путь активен
                >
                  ГЛАВНАЯ
                </a>
                <a
                    href="/catalog"
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/catalog')
                    }}
                    className={`title ${isActive('/catalog') ? 'active' : ''}`}
                >
                  КАТАЛОГ
                </a>
                <a
                    href="/designers"
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/designers')
                    }}
                    className={`title ${isActive('/designers') ? 'active' : ''}`}
                >
                  ДИЗАЙНЕРАМ
                </a>
                <a
                    href="/about"
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/about')
                    }}
                    className={`title ${isActive('/about') ? 'active' : ''}`}
                >
                  О НАС
                </a>
                <a
                    href="/contacts"
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/contacts')
                    }}
                    className={`title ${isActive('/contacts') ? 'active' : ''}`}
                >
                  КОНТАКТЫ
                </a>
              </div>
              <div className="drawer-footer">
                <a
                    href="https://wa.me/996500696000"
                    aria-label="Связаться через WhatsApp"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                >
                  <PhoneOutlined />
                </a>
                <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="contact-icon"
                >
                  <InstagramOutlined />
                </a>
              </div>
            </div>
          </div>
        </Drawer>
      </>
  )
}

export default HeaderMenu
