import { motion } from 'framer-motion'
import { useRecoilState } from 'recoil'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import backgroundImage from '../../assets/images/sofa-orange.png'
import { selectedPurposesState } from '../../store/atoms'
import ButtonCatalog from '../button-catalog'

import './style.scss'

const MenuCard = ({
  title,
  items = [],
  buttonText = 'перейти в каталог',
  onButtonClick,
  image = backgroundImage,
  isExpanded,
}) => {
  const navigate = useNavigate()
  const [purposes, setSelectedPurposes] = useRecoilState(selectedPurposesState)

  const onClick = (ids) => {
    setSelectedPurposes(ids)
    navigate('/catalog')
  }

  return (
    <motion.div
      className="menu-card"
      style={{ backgroundImage: `url(${image})` }}
      animate={{
        backgroundColor: isExpanded ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0)',
      }}
      transition={{ duration: 0.5 }}
    >
      <div className="overlay" />
      <div className="content">
        <h2 className="title">{title}</h2>
        <ul className="items">
          {items.map((item) => (
            <li
              onClick={() => onClick(item.filterIds)}
              key={item.id}
            >
              {item.name},
            </li>
          ))}
        </ul>
        <ButtonCatalog
          buttonText={buttonText}
          className={'menu-card-button'}
          white
          onButtonClick={onButtonClick}
        />
      </div>
    </motion.div>
  )
}

export default MenuCard
