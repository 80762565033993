import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import React from 'react'

import './style.scss'

const CustomButton = ({
  color = '#ff00aa',
  text = 'Кнопка',
  showArrow = true,
  arrowDirection = 'right',
  className = '',
  onClick,
  type = ''
}) => {
  return (
    <Button
      className={`custom-button ${className}`}
      style={{ backgroundColor: color, borderColor: color }}
      onClick={onClick}
      htmlType={type}
    >
      {arrowDirection === 'left' && showArrow && (
        <ArrowLeftOutlined
          style={{ fontSize: '20px' }}
          size={20}
          className="arrow-icon left"
        />
      )}
      <div className="text">{text}</div>
      {arrowDirection === 'right' && showArrow && (
        <ArrowRightOutlined
          style={{ fontSize: '20px' }}
          className="arrow-icon right"
        />
      )}
    </Button>
  )
}

export default CustomButton
