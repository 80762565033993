import React from 'react'

import CustomButton from '../button-custom'
import Icon from '../icon'

import './style.scss'

const ButtonCatalog = ({
  buttonText = 'Перейти в каталог',
  onButtonClick,
  nav = false,
  buttonClassName = '',
  className = '',
  white = false,
}) => {
  return (
    <div
      className="catalog-button"
      onClick={onButtonClick}
    >
      <span className={`text ${className}`}>{buttonText}</span>
      {nav ? (
        <CustomButton
          showArrow={false}
          onClick={onButtonClick}
          text={'перейти'}
          className={buttonClassName}
        />
      ) : (
        <Icon
          className={`${className}`}
          name={white ? 'arrow_active' : 'arrow'}
        />
      )}
    </div>
  )
}

export default ButtonCatalog
