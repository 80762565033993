import { CloseOutlined, DownOutlined, RightOutlined } from '@ant-design/icons'
import { Checkbox, Collapse, Drawer } from 'antd'
import { useRecoilState } from 'recoil'

import React, { useEffect, useState } from 'react'

import { MAX_PRICE, MIN_PRICE, SORTING_OPTIONS } from '../../constants/global'
import { useFiltersDataHook } from '../../hooks/useFiltersDataHook'
import {
  DEFAULT_PRODUCTS_PAYLOAD,
  useProductsHook,
} from '../../hooks/useProductsHook'
import {
  selectedCategoriesState,
  selectedDiscoutState,
  selectedMaterialsState,
  selectedPricesState,
  selectedPurposesState,
  selectedSortingState,
  selectedStylesState,
} from '../../store/atoms'
import CustomButton from '../button-custom'
import CheckboxFilter from '../checkbox-filter'
import LoadingSpinner from '../loading-spinner'
import Logo from '../logo'
import PriceFilter from '../price-filter'
import RadioFilter from '../radio-filter'

import './style.scss'

const { Panel } = Collapse

const CatalogFilter = () => {
  const { fetchProducts } = useProductsHook()
  const { fetchFilter, filterData, filterLoading } = useFiltersDataHook()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [selectedSorting, setSelectedSorting] =
    useRecoilState(selectedSortingState)
  const [selectedCategories, setSelectedCategories] = useRecoilState(
    selectedCategoriesState,
  )
  const [selectedMaterials, setSelectedMaterials] = useRecoilState(
    selectedMaterialsState,
  )
  const [selectedStyles, setSelectedStyles] =
    useRecoilState(selectedStylesState)
  const [selectedPurposes, setSelectedPurposes] = useRecoilState(
    selectedPurposesState,
  )
  const [selectedPrices, setSelectedPrices] =
    useRecoilState(selectedPricesState)
  const [selectedDiscount, setSelectedDiscout] =
    useRecoilState(selectedDiscoutState)

  useEffect(() => {
    fetchFilter()
  }, [])

  const showDrawer = () => {
    setDrawerVisible(true)
  }

  const onClose = () => {
    setDrawerVisible(false)
  }

  const handleSorting = (e) => {
    setSelectedSorting(e.target.value)
  }

  const handleReset = () => {
    setSelectedSorting(3)
    setSelectedCategories([])
    setSelectedMaterials([])
    setSelectedStyles([])
    setSelectedPurposes([])
    setSelectedPrices([MIN_PRICE, MAX_PRICE])
    setSelectedDiscout(false)

    fetchProducts(DEFAULT_PRODUCTS_PAYLOAD)
    onClose()
  }

  const handleSubmit = () => {
    fetchProducts()
    onClose()
  }

  return (
    <div className="filter-component">
      <CustomButton
        text="Фильтр"
        className="catalog-filter-button"
        onClick={showDrawer}
        arrowDirection={'left'}
      />

      <Drawer
        title={false}
        placement="left"
        closable={false}
        className="custom-drawer filter-drawer"
        onClose={onClose}
        open={drawerVisible}
      >
        <div className="war">
          <div className="flex alignC justify-s">
            <Logo
              desktopColor={'active'}
              mobileColor={'mobile_active'}
            />
            <CloseOutlined
              className="close-icon"
              onClick={onClose}
            />
          </div>
          <div className="filter-content">
            {filterLoading && <LoadingSpinner />}

            {filterData && !filterLoading && (
              <Collapse
                accordion
                ghost
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) =>
                  isActive ? <DownOutlined /> : <RightOutlined />
                }
                className="filter-collapse"
              >
                <Panel
                  header="Сортировка"
                  key="0"
                >
                  <RadioFilter
                    options={SORTING_OPTIONS}
                    selected={selectedSorting}
                    setSelected={handleSorting}
                  />
                </Panel>

                <div className="filter-discount">
                  <Checkbox
                    checked={selectedDiscount}
                    onChange={(e) => setSelectedDiscout(e.target.checked)}
                  >
                    <span class="discount-label">скидки</span>
                  </Checkbox>
                </div>

                <Panel
                  header="Категория"
                  key="1"
                >
                  <CheckboxFilter
                    options={filterData.categories}
                    selected={selectedCategories}
                    setSelected={setSelectedCategories}
                  />
                </Panel>

                <Panel
                  header="Цена"
                  key="2"
                >
                  <PriceFilter
                    value={selectedPrices}
                    setValue={setSelectedPrices}
                  />
                </Panel>

                <Panel
                  header="Материал"
                  key="3"
                >
                  <CheckboxFilter
                    options={filterData.materials}
                    selected={selectedMaterials}
                    setSelected={setSelectedMaterials}
                  />
                </Panel>

                <Panel
                  header="Стиль"
                  key="4"
                >
                  <CheckboxFilter
                    options={filterData.styles}
                    selected={selectedStyles}
                    setSelected={setSelectedStyles}
                  />
                </Panel>

                <Panel
                  header="Назначение"
                  key="5"
                >
                  <CheckboxFilter
                    options={filterData.purposes}
                    selected={selectedPurposes}
                    setSelected={setSelectedPurposes}
                  />
                </Panel>
              </Collapse>
            )}
          </div>
          <div className="filter-buttons">
            <CustomButton
              color="#a6a6a6"
              className="catalog-filter-button"
              showArrow={false}
              text="сбросить"
              onClick={handleReset}
            />
            <CustomButton
              className="catalog-filter-button"
              showArrow={false}
              text="применить"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default CatalogFilter
