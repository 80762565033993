import { Carousel, Image } from 'antd'

import React from 'react'

import './style.scss'

const GalleryGrid = ({ images }) => {
  const contentStyle = {
    // margin: 0,
    // height: '640px', //TODO зачем тут height? ломает мобилку верстку
    // witdth: '100%',
    // color: '#000',
    // lineHeight: '160px',
    // textAlign: 'center',
    // background: '#fff',
  }
  return (
    <Carousel
      arrows
      adaptiveHeight
      className="gallery-grid"
    >
      {images.map((img) => (
        <div key={img.id}>
          <Image
            className="img"
            src={img.fullName}
          />
        </div>
      ))}
    </Carousel>
  )
}

export default GalleryGrid
