import { Result } from 'antd'

import React from 'react'

const InfoComp = ({ children, text, extra, status }) => (
  <Result
    status={status}
    title={text}
    extra={extra}
  >
    {children}
  </Result>
)
export default InfoComp
