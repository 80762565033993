import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import AdminPanel from '../../pages/admin'

const ProtectedRoute = () => {
  const isAuthenticated = localStorage.getItem('token')

  if (isAuthenticated) {
    return (
      <AdminPanel>
        <Outlet />
      </AdminPanel>
    )
  }

  return (
    <Navigate
      to="/login"
      replace
    />
  )
}

export default ProtectedRoute
