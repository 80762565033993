import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import FurnitureCard from '../../components/furniture-сard'
import Header from '../../components/header'
import { RecommendationsData } from '../../data/catalogItem'

import './style.scss'

const FurnitureCare = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const navigate = useNavigate()

  const getRow = (index) => Math.floor(index / 2)

  const handleClick = (index) => {
    if (index === 0) {
      navigate('/recommendations/article')
    } else {
    }
  }

  const cards = RecommendationsData.map((item, i) => {
    const buttonText = i === 0 ? 'по уходу и чистке' : ''
    return (
      <div
        key={item.id}
        className={`furniture-card-wrapper ${
          hoveredIndex === i
            ? 'expanded'
            : getRow(hoveredIndex) === getRow(i) && hoveredIndex !== null
              ? 'collapsed'
              : ''
        }`}
        onClick={() => handleClick(i)}
        onMouseEnter={() => setHoveredIndex(i)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <FurnitureCard
          isExpanded={hoveredIndex === i}
          title={item.title}
          image={item.img}
          buttonText={buttonText}
        />
      </div>
    )
  })

  return (
    <div className="container furniture-care">
      <Header showBreadcrumbs />
      <div className="FurnitureCollection">
        <div className="flex">{cards.slice(0, 2)}</div>
        <div className="flex">{cards.slice(2, 4)}</div>
        <div className="flex">{cards.slice(4, 6)}</div>
        <div className="flex">{cards[6]}</div>
      </div>
    </div>
  )
}

export default FurnitureCare
