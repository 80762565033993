import React from 'react'
import { useNavigate } from 'react-router-dom'

import backgroundImage2 from '../../assets/images/project2.png'
import backgroundImage from '../../assets/images/project.png'
import ProjectCard from '../project-card'

const ProjectSection = () => {
  const navigate = useNavigate()

  const handleDesignersClick = () => {
    navigate('/designers')
  }

  const handleRecommendationsClick = () => {
    navigate('/recommendations')
  }

  return (
    <div>
      <ProjectCard
        image={backgroundImage}
        title="дизайнерам"
        text="Комплектуем проекты из наличия"
        buttonText="Подробнее"
        backgroundColor="#E135F0"
        onButtonClick={handleDesignersClick}
      />
      <ProjectCard
        image={backgroundImage2}
        title="Рекомендации "
        text="по уходу и чистке"
        buttonText="Подробнее"
        backgroundColor="#87EEFC"
        onButtonClick={handleRecommendationsClick}
      />
    </div>
  )
}

export default ProjectSection
