import { Button, Flex, Input, Popconfirm, Table } from 'antd'
import axios from 'axios'

import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import CustomButton from '../../../../components/button-custom'
import { useFiltersDataHook } from '../../../../hooks/useFiltersDataHook'

const Purposes = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [isLoading, setIsloading] = useState(false)
  const { fetchPurposes } = useFiltersDataHook()

  const fetch = async () => {
    setIsloading(true)
    const response = await fetchPurposes()
    setData(response)
    setIsloading(false)
  }
  useEffect(() => {
    fetch()
  }, [])

  const handleRemove = async (id) => {
    await axios.delete(`/purposes/${id}`)
    fetch()
  }

  const handleEdit = (id) => {
    navigate(`/admin/purpose/update/${id}`)
  }

  const sortAlphabet = (a, b, field) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a[field].toUpperCase()
    const bandB = b[field].toUpperCase()

    let comparison = 0
    if (bandA > bandB) {
      comparison = 1
    } else if (bandA < bandB) {
      comparison = -1
    }
    return comparison
  }

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sortAlphabet(a, b, 'name'),
    },

    {
      title: 'Действия',
      key: 'actions',
      render: (value) => {
        return (
          <Flex
            gap="small"
            align="center"
          >
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(value.id)}
            >
              Редактировать
            </Button>
            <Popconfirm
              title="Удалить назначение"
              description="Вы уверены что хотите удалить это назначение?"
              onConfirm={() => handleRemove(value.id)}
              onCancel={() => {}}
              okText="Да"
              cancelText="Нет"
            >
              <Button
                size="small"
                danger
              >
                Удалить
              </Button>
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  const filteredData = data
    .filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))
    .map((p) => ({ ...p, key: p.id }))
  return (
    <div>
      <Flex
        gap="small"
        align="center"
      >
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Поиск"
        />
        <CustomButton
          text={'Добавить'}
          showArrow={false}
          onClick={() => navigate('/admin/purpose/create')}
        />
      </Flex>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
        pagination={{
          pageSize: 20,
        }}
      />
    </div>
  )
}

export default Purposes
