import React from 'react'

import img from '../../assets/images/chair2.png'

import './style.scss'

const RecommendationTitle = () => {
  return (
    <div className="recommendation-container">
      <div className="text-section">
        <div className="main-title title">ОБЩИЕ РЕКОМЕНДАЦИИ</div>
        <div className=" title subtitle">ПО УХОДУ И ЧИСТКЕ</div>
        <div className="description text">
          Очистка каждого пятна зависит от природы его появления и вида ткани,
          однако здесь есть и общие правила:
        </div>
      </div>
      <div className="image-section">
        <img
          src={img}
          alt="Chair"
          className="recommendation-image"
        />
      </div>
    </div>
  )
}

export default RecommendationTitle
