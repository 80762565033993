import { RecoilRoot } from 'recoil'

import React, { Suspense } from 'react'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'

import Footer from './components/footer'
import LoadingSpinner from './components/loading-spinner'
import ProtectedRoute from './components/protected-route/ProtectedRoute'
import ScrollToTop from './components/scroll-to-top'
import init from './init'
import About from './pages/about/About'
import EditCategory from './pages/admin/components/categories/EditCategory'
import CategoryForm from './pages/admin/components/categories/Form'
import Categories from './pages/admin/components/categories/index'
import LoginFrom from './pages/admin/components/login/login'
import Materials from './pages/admin/components/materials'
import EditMaterial from './pages/admin/components/materials/EditMaterial'
import MaterialForm from './pages/admin/components/materials/Form'
import EditProduct from './pages/admin/components/products/EditProduct'
import ProductForm from './pages/admin/components/products/ProductForm'
import ProductList from './pages/admin/components/products/index'
import Purposes from './pages/admin/components/purposes'
import EditPurpose from './pages/admin/components/purposes/EditPurpose'
import PurposeForm from './pages/admin/components/purposes/Form'
import Styles from './pages/admin/components/styles'
import EditStyle from './pages/admin/components/styles/EditStyle'
import StyleForm from './pages/admin/components/styles/Form'
import CatalogDetail from './pages/catalog-detail/CatalogDetail'
import Catalog from './pages/catalog/Catalog'
import Contacts from './pages/contacts/Contacts'
import Designers from './pages/designers/Designers'
import FurnitureCare from './pages/furniture-care/FurnitureCare'
import Main from './pages/main/Main'
import Recommendations from './pages/recommendations/Recommendations'

init()

const App = () => {
  const isAdminPanel = window.location.pathname.includes('admin')

  return (
    <RecoilRoot>
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <ScrollToTop>
            <Routes>
              <Route
                path="/"
                element={<Main />}
              />
              <Route
                path="/catalog"
                element={<Catalog />}
              />

              <Route
                path="/catalog/:id"
                element={<CatalogDetail />}
              />

              <Route
                path="/designers"
                element={<Designers />}
              />

              <Route
                path="/recommendations"
                element={<FurnitureCare />}
              />

              <Route
                path="/about"
                element={<About />}
              />

              <Route
                path="/contacts"
                element={<Contacts />}
              />

              <Route
                path="/recommendations/article"
                element={<Recommendations />}
              />

              <Route
                path="/login"
                element={<LoginFrom />}
              />
              <Route
                path="/register"
                element={<LoginFrom isRegister />}
              />

              <Route element={<ProtectedRoute />}>
                <Route
                  path="/admin"
                  element={<ProductList />}
                />
                <Route
                  path="/admin/product/create"
                  element={<ProductForm />}
                />
                <Route
                  path="/admin/product/update/:id"
                  element={<EditProduct />}
                />

                <Route
                  path="/admin/categories"
                  element={<Categories />}
                />
                <Route
                  path="/admin/category/create"
                  element={<CategoryForm />}
                />
                <Route
                  path="/admin/category/update/:id"
                  element={<EditCategory />}
                />

                <Route
                  path="/admin/materials"
                  element={<Materials />}
                />
                <Route
                  path="/admin/material/create"
                  element={<MaterialForm />}
                />
                <Route
                  path="/admin/material/update/:id"
                  element={<EditMaterial />}
                />

                <Route
                  path="/admin/styles"
                  element={<Styles />}
                />
                <Route
                  path="/admin/style/create"
                  element={<StyleForm />}
                />
                <Route
                  path="/admin/style/update/:id"
                  element={<EditStyle />}
                />

                <Route
                  path="/admin/purposes"
                  element={<Purposes />}
                />
                <Route
                  path="/admin/purpose/create"
                  element={<PurposeForm />}
                />
                <Route
                  path="/admin/purpose/update/:id"
                  element={<EditPurpose />}
                />
              </Route>

              <Route
                path="*"
                element={
                  <Navigate
                    to="/"
                    replace
                  />
                }
              />
            </Routes>
          </ScrollToTop>
        </Suspense>
        {!isAdminPanel && <Footer />}
      </Router>
    </RecoilRoot>
  )
}

export default App
