import axios from 'axios'

import { BASE_URL } from './api'

export default function init() {
  axios.defaults.baseURL = BASE_URL
  axios.defaults.headers.common['Authorization'] =
    `Bearer ${localStorage.getItem('token')}`

    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.status === 401) {
        localStorage.removeItem('token')
        window.location.href = '/login'
      }

      return Promise.reject(error);
    });
}
