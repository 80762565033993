import { Breadcrumb } from 'antd'
import { useRecoilValue } from 'recoil'

import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { productDetailsSelector } from '../../store/selector'

import './style.scss'

const breadcrumbNameMap = {
  '/': 'Главная',
  '/catalog': 'Каталог',
  '/recommendations': 'Рекомендации',
  '/recommendations/article': 'статья',
  '/contacts': 'Контакты',
}

const BreadCrumbs = () => {
  const location = useLocation()
  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const productDetails = useRecoilValue(productDetailsSelector)

  const breadcrumbItems = [
    {
      key: '/',
      title: (
        <Link
          to="/"
          className="text"
        >
          Главная страница
        </Link>
      ),
    },
  ]

  breadcrumbItems.push(
    ...pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      const isLast = index === pathSnippets.length - 1
      let name = breadcrumbNameMap[url]

      if (!name && url.startsWith('/catalog/') && productDetails) {
        name = productDetails.name
      }

      return {
        key: url,
        title: isLast ? name || url : <Link to={url}>{name || url}</Link>,
        className: isLast ? 'text last-item' : '',
      }
    }),
  )

  return (
    <div className="bread-crumb">
      <Breadcrumb
        separator=">"
        className="text"
        items={breadcrumbItems}
      />
    </div>
  )
}

export default BreadCrumbs
