import react, { useState } from 'react'
import axios from 'axios'
import { useRecoilState, useRecoilValue } from 'recoil'
import { MAX_PRICE, MIN_PRICE } from '../constants/global'
import { productsState, recommendProductsState } from '../store/atoms'
import { totalProductsState } from '../store/atoms'
import { productDetailsState } from '../store/atoms'
import {
  mainSeearchSelector,
  selectedCategoriesSelector,
  selectedDiscoutSelector,
  selectedMaterialsSelector,
  selectedPricesSelector,
  selectedPurposesSelector,
  selectedSortingSelector,
  selectedStylesSelector,
} from '../store/selector'

export const PAGE_LIMIT = 16

export const DEFAULT_PRODUCTS_PAYLOAD = {
  search: '',
  categoryIds: [],
  materialIds: [],
  styleIds: [],
  purposeIds: [],
  isDiscounted: undefined,
  priceFrom: MIN_PRICE,
  priceTo: MAX_PRICE,
  limit: PAGE_LIMIT,
  offset: 0,
  sorting: 3,
}

export const useProductsHook = () => {
  const [products, setProducts] = useRecoilState(productsState)
  const [productDetails, setProductDetails] = useRecoilState(productDetailsState)
  const [totalProducts, setTotalProducts] = useRecoilState(totalProductsState)
  const [productsLoading, setProductsLoading] = useState(false)
  const [productLoading, setProductLoading] = useState(false)

  const categoryIds = useRecoilValue(selectedCategoriesSelector)
  const materialIds = useRecoilValue(selectedMaterialsSelector)
  const styleIds = useRecoilValue(selectedStylesSelector)
  const purposeIds = useRecoilValue(selectedPurposesSelector)
  const prices = useRecoilValue(selectedPricesSelector)
  const isDiscounted = useRecoilValue(selectedDiscoutSelector)
  const search = useRecoilValue(mainSeearchSelector)
  const sorting = useRecoilValue(selectedSortingSelector)

  const resetProducts = () => {
    setProducts([])
  }
  const fetchProducts = async (values) => {
    try {
      setProductsLoading(true)
      const payload = {
        search,
        categoryIds,
        materialIds,
        styleIds,
        purposeIds,
        isDiscounted: isDiscounted ? isDiscounted : undefined,
        priceFrom: prices[0],
        priceTo: prices[1],
        limit: PAGE_LIMIT,
        offset: 0,
        sorting,
        ...values,
      }
      const res = await axios.post('furniture/search', payload)

      setProducts(res.data.data)
      setTotalProducts(res.data.totalCount)
    } catch (e) {
      console.error('fetch products error', e)
    } finally {
      setProductsLoading(false)
    }
  }

  const fetchProduct = async (id) => {
    try {
      setProductLoading(true)
      const res = await axios.get(`furniture/${id}`)

      setProductDetails(res.data)
    } catch (e) {
      console.error('fetch product details error', e)
    } finally {
      setProductLoading(false)
    }
  }

  return {
    resetProducts,
    fetchProducts,
    products,
    productsLoading,
    fetchProduct,
    productDetails,
    productLoading,
  }
}
