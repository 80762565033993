import { Button, Flex, Form, Input, message } from 'antd'
import axios from 'axios'

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const MaterialForm = ({ data }) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  useEffect(() => {
    if (!data) return
    const values = { name: data.name }

    form.setFieldsValue(values)
  }, [data])

  const onFinish = async (values) => {
    try {
      if (data) {
        await axios.put(`/materials/${data.id}`, null, {
          params: { ...values },
        })
      } else {
        await axios.post('/materials', null, {
          params: { ...values },
        })

        resetForm()
        message.success('Материал сохранен успешно.')
        handleBack()
      }
    } catch (error) {
      message.error('Ошибка сохранения')
      console.error('Error:', error)
    }
  }

  const resetForm = () => {
    form.resetFields()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const handleBack = () => {
    navigate('/admin/materials')
  }

  const title = data ? 'Редактирование Материала' : 'Добавление Материала'
  return (
    <Form
      form={form}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      style={{
        maxWidth: 1400,
        padding: 80,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <h1
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {title}
      </h1>

      <Form.Item
        label="Название"
        name="name"
        rules={[
          {
            required: true,
            message: 'Обязательное поле!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Flex gap="small">
          <Button
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
          <Button onClick={handleBack}>Отмена</Button>
        </Flex>
      </Form.Item>
    </Form>
  )
}
export default MaterialForm
