import { InstagramOutlined, WhatsAppOutlined } from '@ant-design/icons'

import buildingImg from '../../assets/images/contacts/building.png'
import mapImg from '../../assets/images/contacts/map.png'
import shopImg from '../../assets/images/contacts/shop.png'
import Header from '../../components/header'
import Icon from '../../components/icon'

import './style.scss'

const Contacts = () => {
  return (
    <div className="contacts container">
      <Header showBreadcrumbs />
      <div>
        <div className="contacts-block">
          <div className="wrapper">
            <div className="cont-section">
              <h3 className="title">ЕЖЕДНЕВНО</h3>
              <p className="text">10.00-20.00</p>
            </div>
            <div className="cont-section">
              <h3 className="title">БЦ "МОНОЛИТ"</h3>
              <p className="text">Улица Льва Толстого, 36к 2 этаж, А2 бутик</p>
            </div>
            <div className="cont-section">
              <h3 className="title">+996 500 69 60 00</h3>
              <p className="text">Звонки, WhatsApp</p>
            </div>

            <div className="social-icons">
              <a
                href="https://www.instagram.com/medinakas.kg?igsh=cTIxcjVva2NnamVv"
                aria-label="Наш Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramOutlined
                  style={{ fontSize: '32px', color: '#000' }}
                />
              </a>
              <a
                href="https://wa.me/996500696000"
                aria-label="Связаться через WhatsApp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppOutlined
                  style={{
                    fontSize: '32px',
                    color: '#000',
                    marginLeft: '20px',
                  }}
                />
              </a>
            </div>

            <div className="2gis">
              <Icon
                  hover
                name="2gis-black"
                onClick={() =>
                  window.open(
                    'https://2gis.kg/bishkek/geo/70000001083438560',
                    '_blank',
                  )
                }
              />
            </div>
          </div>
          <div className="contacts-image">
            <img src={shopImg} />
          </div>
        </div>
        <div className="contacts-block">
          <div className="contacts-image">
            <img src={mapImg} />
          </div>
          <div className="contacts-image">
            <img src={buildingImg} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts
