import { Input, Modal } from 'antd'

import React, { useState } from 'react'

import CustomButton from '../button-custom'

import './style.scss'

const ButtonModal = ({ text = 'узнать подробнее', className }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <CustomButton
        showArrow={false}
        className={className}
        text={text}
        onClick={showModal}
      />

      <Modal
        title={null}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="custom-modal"
        closeIcon={<span className="custom-close-icon">×</span>}
      >
        <form
          action="https://formspree.io/f/xnnakvwb"
          method="POST"
          className="modal-content"
        >
          <p className="text">
            Для оформления заказа или получения информации, <br />
            <span className="text">
              пожалуйста, оставьте контактные данные для связи:
            </span>
          </p>
          <Input
            type="text"
            name="name"
            className="custom-input"
            placeholder="Ваше имя"
            required
          />
          <Input
            type="text"
            name="phone"
            className="custom-input"
            placeholder="Ваш телефон"
            required
          />
          <CustomButton
            showArrow={false}
            text={'получить консультацию'}
            className="modal-btn"
            type="submit"
          />
        </form>
      </Modal>
    </>
  )
}

export default ButtonModal
