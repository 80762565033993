import { motion } from 'framer-motion'

import React, { useState } from 'react'

import { FurnitureWithMenuData, mockMenuPurposes } from '../../data/catalogItem'
import FurnitureCard from '../furniture-сard'
import MenuCard from '../menu-card'

import './style.scss'

const FurnitureWithMenu = ({ redirectToCatalog }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const backgroundColors = ['#94C4DC', '#D9D9D9']

  return (
    <div className="FurnitureWithMenu">
      <div className="left-column">
        {FurnitureWithMenuData.slice(0, 2).map((item, index) => (
          <motion.div
            key={item.id}
            className="card-wrapper"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            animate={{
              flexGrow: hoveredIndex === index ? 1.3 : 1,
            }}
            transition={{ duration: 0.7 }}
            onClick={() => redirectToCatalog(item.id)}
          >
            <FurnitureCard
              isExpanded={hoveredIndex === index}
              title={item.title}
              image={item.img}
              backgroundColor={backgroundColors[index]}
            />
          </motion.div>
        ))}
      </div>

      <motion.div
        className="right-column"
        onMouseEnter={() => setHoveredIndex(2)}
        onMouseLeave={() => setHoveredIndex(null)}
        animate={{
          flexGrow: hoveredIndex === 2 ? 1.4 : 1,
        }}
        transition={{ duration: 0.5 }}
      >
        <MenuCard
          isExpanded={hoveredIndex === 2}
          title={FurnitureWithMenuData[2].title}
          items={mockMenuPurposes}
        />
      </motion.div>
    </div>
  )
}

export default FurnitureWithMenu
