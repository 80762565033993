import React from 'react'

import Icon from '../icon'

import './style.scss'

const Logo = ({ desktopColor = 'black', mobileColor = 'mobile' }) => {
  return (
    <div className="logo">
      <Icon
        name={`logo_${desktopColor}`}
        className="logo-desktop"
      />
      <Icon
        name={`logo_${mobileColor}`}
        className="logo-mobile"
      />
    </div>
  )
}

export default Logo
