import { Button, Flex, Input, Popconfirm, Table, Tag } from 'antd'
import axios from 'axios'

import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import CustomButton from '../../../../components/button-custom'
import { useProductsHook } from '../../../../hooks/useProductsHook'

const ProductList = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const { fetchProducts, products, productsLoading } = useProductsHook()

  useEffect(() => {
    fetchProducts({ limit: 100000 })
  }, [])

  const handleRemove = async (id) => {
    await axios.delete(`/furniture/${id}`)
    fetchProducts({ limit: 100000 })
  }

  const handleEdit = (id) => {
    navigate(`/admin/product/update/${id}`)
  }

  const sortAlphabet = (a, b, field) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a[field].toUpperCase()
    const bandB = b[field].toUpperCase()

    let comparison = 0
    if (bandA > bandB) {
      comparison = 1
    } else if (bandA < bandB) {
      comparison = -1
    }
    return comparison
  }

  const columns = [
    {
      title: 'Картинка',
      dataIndex: 'images',
      key: 'image',
      render: (value) => {
        const smalls = value.filter((img) => img.isSmall)
        const imgSrc = smalls[0]?.fullName || ''
        return (
          <img
            width={100}
            src={imgSrc}
          />
        )
      },
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => sortAlphabet(a, b, 'name'),
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: 'Скидки',
      dataIndex: 'discount',
      key: 'discount',
      sorter: (a, b) => a.discount - b.discount,
      render: (value) => {
        return <div>{value ? `${value}%` : 'Нет'}</div>
      },
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      key: 'category',
      render: (_, { category }) => <>{category.name}</>,
    },

    {
      title: 'Материалы',
      dataIndex: 'materials',
      key: 'materials',
      render: (_, { materials }) => {
        return (
          <>
            {materials.map((tag) => {
              return (
                <Tag
                  color="green"
                  key={tag.id}
                >
                  {tag.name}
                </Tag>
              )
            })}
          </>
        )
      },
    },

    {
      title: 'Стили',
      dataIndex: 'styles',
      key: 'styles',
      render: (_, { styles }) => {
        return (
          <>
            {styles.map((tag) => {
              return (
                <Tag
                  color="magenta"
                  key={tag.id}
                >
                  {tag.name}
                </Tag>
              )
            })}
          </>
        )
      },
    },
    {
      title: 'Назначение',
      dataIndex: 'purposes',
      key: 'purposes',
      render: (_, { purposes }) => {
        return (
          <>
            {purposes.map((tag) => {
              return (
                <Tag
                  color="orange"
                  key={tag.id}
                >
                  {tag.name}
                </Tag>
              )
            })}
          </>
        )
      },
    },

    {
      title: 'Действия',
      key: 'actions',
      render: (value) => {
        return (
          <Flex
            gap="small"
            align="center"
          >
            <Button
              size="small"
              type="primary"
              onClick={() => handleEdit(value.id)}
            >
              Редактировать
            </Button>
            <Popconfirm
              title="Удалить товар"
              description="Вы уверены что хотите удалить этот товар?"
              onConfirm={() => handleRemove(value.id)}
              onCancel={() => {}}
              okText="Да"
              cancelText="Нет"
            >
              <Button
                size="small"
                danger
              >
                Удалить
              </Button>
            </Popconfirm>
          </Flex>
        )
      },
    },
  ]

  const filteredProducts = products
    .filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))
    .map((p) => ({ ...p, key: p.id }))
  return (
    <div>
      <Flex
        gap="small"
        align="center"
      >
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Поиск"
        />
        <CustomButton
          text={'Добавить'}
          showArrow={false}
          onClick={() => navigate('/admin/product/create')}
        />
      </Flex>
      <Table
        loading={productsLoading}
        columns={columns}
        dataSource={filteredProducts}
      />
    </div>
  )
}

export default ProductList
