import React, { useState } from 'react'

import './style.scss'

const ProductCard = ({ product, onClick }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { name, images, id } = product

  const filtered = images.filter((i) => !!i.isSmall)
  const smallImages = filtered.length > 0 ? filtered : images

  const baseImage = smallImages[0]
  const hoverImage = smallImages.length > 1 ? smallImages[1] : baseImage
  return (
    <div
      key={id}
      className="product-card"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="image-wrapper">
        <img
          src={isHovered ? hoverImage.fullName : baseImage.fullName}
          alt={name}
        />
        <div className="card-overlay " />
      </div>
      <div className={`product-name text ${isHovered ? 'hovered' : ''}`}>
        {name}
      </div>
    </div>
  )
}

export default ProductCard
