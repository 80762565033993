import { selector } from 'recoil'

import {
  selectedCategoriesState,
  selectedMaterialsState,
  selectedStylesState,
  selectedPurposesState,
  productsState,
  selectedPricesState,
  selectedSortingState,
  selectedDiscoutState,
  filtersDataState,
  mainSearchState,
  totalProductsState,
  offsetState,
  tagsDataState,
  productDetailsState,
} from './atoms'


export const productsSelector = selector({
  key: 'productsSelector',
  get: ({ get }) => get(productsState),
})

export const productDetailsSelector = selector({
  key: 'productDetailsSelector',
  get: ({ get }) => get(productDetailsState),
})

export const filterDataSelector = selector({
  key: 'filterDataSelector',
  get: ({ get }) => get(filtersDataState),
})

export const selectedSortingSelector = selector({
  key: 'selectedSortingSelector',
  get: ({ get }) => get(selectedSortingState),
})

export const selectedCategoriesSelector = selector({
  key: 'selectedCategoriesSelector',
  get: ({ get }) => get(selectedCategoriesState),
})

export const selectedMaterialsSelector = selector({
  key: 'selectedMaterialsSelector',
  get: ({ get }) => get(selectedMaterialsState),
})

export const selectedStylesSelector = selector({
  key: 'selectedStylesSelector',
  get: ({ get }) => get(selectedStylesState),
})

export const selectedPurposesSelector = selector({
  key: 'selectedPurposesSelector',
  get: ({ get }) => get(selectedPurposesState),
})

export const selectedPricesSelector = selector({
  key: 'selectedPricesSelector',
  get: ({ get }) => get(selectedPricesState),
})

export const selectedDiscoutSelector = selector({
  key: 'selectedDiscoutSelector',
  get: ({ get }) => get(selectedDiscoutState),
})

export const mainSeearchSelector = selector({
  key: 'mainSeearchSelector',
  get: ({ get }) => get(mainSearchState),
})

export const offsetSelector = selector({
  key: 'offsetSelector',
  get: ({ get }) => get(offsetState),
})

export const totalProductsSelector = selector({
  key: 'totalProductsSelector',
  get: ({ get }) => get(totalProductsState),
})
 