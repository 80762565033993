import { InstagramOutlined, WhatsAppOutlined } from '@ant-design/icons'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import Icon from '../icon'

import './style.scss'

const Footer = () => {
  const navigate = useNavigate()

  return (
    <footer className="footer">
      <div className="mira">
        <div className="footer-container">
          <div className="footer-column">
            <div className="footer-section">
              <h3 className="title">ЕЖЕДНЕВНО</h3>
              <p className="text">10.00-20.00</p>
            </div>
            <div className="footer-section">
              <h3 className="title">БЦ "МОНОЛИТ"</h3>
              <p className="text">Улица Льва Толстого, 36к 2 этаж, А2 бутик</p>
            </div>
            <div className="footer-section">
              <h3 className="title">+996 500 69 60 00</h3>
              <p className="text">Звонки, WhatsApp</p>
            </div>
          </div>
          <nav className="footer-column-nav footer-column">
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault()
                navigate('/')
              }}
              aria-label="Главная страница"
              className="text nav-text"
            >
              Главная
            </a>
            <a
              href="/contacts"
              onClick={(e) => {
                e.preventDefault()
                navigate('/contacts')
              }}
              aria-label="Контакты"
              className="text nav-text"
            >
              Контакты
            </a>
            <a
              href="/designers"
              onClick={(e) => {
                e.preventDefault()
                navigate('/designers')
              }}
              aria-label="Для дизайнеров"
              className="text nav-text"
            >
              Дизайнерам
            </a>
            <a
              href="/catalog"
              onClick={(e) => {
                e.preventDefault()
                navigate('/catalog')
              }}
              aria-label="Каталог товаров"
              className="text nav-text"
            >
              Каталог
            </a>
            <a
              href="/about"
              onClick={(e) => {
                e.preventDefault()
                navigate('/about')
              }}
              aria-label="О нас"
              className="text nav-text"
            >
              О нас
            </a>
          </nav>
          <div className="footer-column footer-social">
            <div className="war">
              <Icon
                hover
                name="2gis"
                onClick={() =>
                  window.open(
                    'https://2gis.kg/bishkek/geo/70000001083438560',
                    '_blank',
                  )
                }
              />

              <div className="social-icons">
                <a
                  href="https://www.instagram.com/medinakas.kg?igsh=cTIxcjVva2NnamVv"
                  aria-label="Наш Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramOutlined
                    style={{ fontSize: '32px', color: '#fff' }}
                  />
                </a>
                <a
                  href="https://wa.me/996500696000"
                  aria-label="Связаться через WhatsApp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppOutlined
                    className="social-icons"
                    style={{
                      fontSize: '32px',
                      color: '#fff',
                      marginLeft: '20px',
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
