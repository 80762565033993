import { Spin } from 'antd'

import React from 'react'

import './style.scss'

const LoadingSpinner = ({ size = 'large', tip = '', fullScreen = false }) => (
  <div className={`loading-spinner ${fullScreen ? 'fullscreen' : ''}`}>
    <Spin
      className="spinner-card"
      size={size}
      tip={tip}
    />
  </div>
)

export default LoadingSpinner
