import React from 'react'
import Marquee from 'react-marquee-slider'

import { mockImages } from '../../data/catalogItem'

import './style.scss'

const MarqueeCarousel = () => {
  return (
    <div className="carousel-container">
      <div className="carousel-title">Нам ДОВЕРЯЮТ</div>
      <Marquee velocity={100}>
        {mockImages.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`slide-${index}`}
            className="carousel-image"
          />
        ))}
      </Marquee>
    </div>
  )
}

export default MarqueeCarousel
