import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Header from '../../components/header'
import LoadingSpinner from '../../components/loading-spinner'
import ProductRecommendation from '../../components/product-recommendation'
import { useProductsHook } from '../../hooks/useProductsHook'

import './style.scss'

const CatalogDetail = () => {
  const { id } = useParams()
  const { fetchProduct, productDetails, productLoading } = useProductsHook()
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768)

  useEffect(() => {
    fetchProduct(id)
  }, [id])

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="container catalog-detail">
      <Header
        showBreadcrumbs
        showSearchIcon={false}
        isAbsolute={isMobileView ? true : false}
      />
      <div className="content">
        {productLoading && <LoadingSpinner />}
        {!productLoading && productDetails && (
          <ProductRecommendation product={productDetails} />
        )}
      </div>
    </div>
  )
}

export default CatalogDetail
