import { motion } from 'framer-motion'

import React from 'react'

import CustomButton from '../button-custom'

import './style.scss'

const ProjectCard = ({
  image,
  title,
  text,
  buttonText,
  onButtonClick,
  backgroundColor,
}) => {
  return (
    <motion.div
      className="project-card"
      style={{
        backgroundImage: `url(${image})`,
        backgroundBlendMode: 'multiply',
      }}
      whileHover={{ height: 450, backgroundColor: backgroundColor }}
      transition={{ duration: 0.1 }}
    >
      <div className="overlay" />
      <motion.div className="content">
        <div className="flex gap-20 flex-column">
          <h2 className="title">{title}</h2>
          <p className="text">{text}</p>
        </div>
        <CustomButton
          text={buttonText}
          showArrow={false}
          className="project-button"
          onClick={onButtonClick}
        />
      </motion.div>
    </motion.div>
  )
}

export default ProjectCard
