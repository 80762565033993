import { useRecoilState } from 'recoil'

import React, { useEffect, useState } from 'react'

import { FurnitureCollectionData } from '../../data/catalogItem'
import { selectedCategoriesState } from '../../store/atoms'
import FurnitureCard from '../furniture-сard'
import Icon from '../icon'

import './style.scss'

const FurnitureCollection = ({ redirectToCatalog }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [selectedCategories, setSelectedCategories] = useRecoilState(
    selectedCategoriesState,
  )
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const getRow = (index) => Math.floor(index / 2)

  const backgroundColors = [
    '#E2DF8F',
    '#D9635E',
    '#D0C3FC',
    '#A2C2F4',
    '#FF7B7A',
    '#E8EFC4',
  ]

  const cards = FurnitureCollectionData.map((item, i) => (
    <div
      key={item.id}
      className={`furniture-card-wrapper ${
        hoveredIndex === i
          ? 'expanded'
          : getRow(hoveredIndex) === getRow(i) && hoveredIndex !== null
            ? 'collapsed'
            : ''
      }`}
      onClick={() => redirectToCatalog(item.id)}
      onMouseEnter={() => setHoveredIndex(i)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <FurnitureCard
        isExpanded={hoveredIndex === i}
        backgroundColor={backgroundColors[i]}
        title={item.title}
        image={item.img}
      />
    </div>
  ))

  return (
    <div className="FurnitureCollection">
      <div className="flex">{cards.slice(0, 2)}</div>
      <div className="flex">{cards.slice(2, 4)}</div>
      <div className="flex">{cards.slice(4, 6)}</div>
      <div className="item">
        <a
          href="/catalog"
          className="all"
        >
          СМОТРЕТЬ ВСЕ ТОВАРЫ
        </a>
        {isMobile ? <Icon name={'arrow_active'} /> : <Icon name={'arrow'} />}
      </div>
    </div>
  )
}

export default FurnitureCollection
