import { Image } from 'antd'
import { useRecoilValue } from 'recoil'

import React from 'react'
import { useNavigate } from 'react-router-dom'

import { totalProductsSelector } from '../../store/selector'
import CustomButton from '../button-custom'
import LoadingSpinner from '../loading-spinner'
import ProductCard from '../product-card'
import { RECOMMEND_LIMIT } from '../product-recommendation'

import './style.scss'

const RecommendedChairs = ({ products, fetching, offset, setOffset }) => {
  const totalProducts = useRecoilValue(totalProductsSelector)
  const navigate = useNavigate()
  const handleProductClick = (id) => {
    navigate(`/catalog/${id}`)
  }

  const handleNext = () => {
    setOffset(offset + RECOMMEND_LIMIT)
  }

  const handlePrev = () => {
    if (offset <= 0) return
    setOffset(offset - RECOMMEND_LIMIT)
  }

  const showMoreButton = products.length < totalProducts

  return (
    <div className="recommended-chairs">
      <h2 className="title">РЕКОМЕНДУЕМ</h2>
      <div className="chairs-grid">
        {fetching && (
            <LoadingSpinner />
        )}
        {!fetching &&
          products.map((product) => {
            return (
              <div
                key={product.id}
                className="chair-item"
              >
                <ProductCard
                  product={product}
                  onClick={() => handleProductClick(product.id)}
                />
              </div>
            )
          })}
      </div>
      <div className="pagination">
        {offset > 0 && (
          <CustomButton
            arrowDirection="left"
            className="catalog-detail-filter-button"
            text={'назад'}
            onClick={handlePrev}
          />
        )}
        {showMoreButton && (
          <CustomButton
            arrowDirection="right"
            className="catalog-detail-filter-button"
            text={'вперед'}
            onClick={handleNext}
          />
        )}
      </div>
    </div>
  )
}

export default RecommendedChairs
