import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { FurnitureShowCaseData } from '../../data/catalogItem'
import ResponsiveFurnitureCard from '../furniture-card-responsive'
import FurnitureCard from '../furniture-сard'

import './style.scss'

const FurnitureShowCase = ({ redirectToCatalog }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const backgroundColors = ['#E7F0AD', '#D9D9D9', '#FFEFA9']

  return (
    <div className="FurnitureShowCase">
      <motion.div
        className="left-column"
        onMouseEnter={() => setHoveredIndex(0)}
        onMouseLeave={() => setHoveredIndex(null)}
        animate={{
          flex: hoveredIndex === 0 ? 1.3 : 1,
        }}
        transition={{ duration: 0.5 }}
        onClick={() => redirectToCatalog(FurnitureShowCaseData[0].id)}
      >
        <ResponsiveFurnitureCard
          isExpanded={hoveredIndex === 0}
          title={FurnitureShowCaseData[0].title}
          image={FurnitureShowCaseData[0].img}
          backgroundColor={backgroundColors[0]}
        />
      </motion.div>

      <div className="right-column">
        {FurnitureShowCaseData.slice(1).map((item, index) => (
          <motion.div
            key={item.id}
            className="card-wrapper"
            onMouseEnter={() => setHoveredIndex(index + 1)}
            onMouseLeave={() => setHoveredIndex(null)}
            animate={{
              flex: hoveredIndex === index + 1 ? 1.3 : 1,
            }}
            transition={{ duration: 0.5 }}
            onClick={() => redirectToCatalog(item.id)}
          >
            <FurnitureCard
              nav={item.nav}
              isExpanded={hoveredIndex === index + 1}
              title={item.title}
              buttonText={item.buttonText}
              backgroundColor={backgroundColors[index + 1]}
              image={item.img}
              onButtonClick={() => redirectToCatalog(item.id)}
            />
          </motion.div>
        ))}
      </div>
    </div>
  )
}

export default FurnitureShowCase
