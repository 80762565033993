import { Image } from 'antd'

import React from 'react'

import './style.scss'

const Slide = ({
  image,
  header,
  number,
  title,
  description,
  className,
  isActive,
  hideTextUntilActive, 
}) => {
  const shouldShowContent = !hideTextUntilActive || isActive

  const height = title === 'MEDINA KAS' ? '130%': undefined
  
  return (
    <div className="slide-container">
      <Image
        src={image}
        alt={title || header}
        width="100%"
        height={height}
        className="slide-image"
        preview={false}
      />
      {shouldShowContent && (
        <div className={`slide-content ${className}`}>
          {number && <p className="title slide-header">{number}</p>}
          {header && <p className="title slide-header">{header}</p>}
          {title && <p className="title slide-title">{title}</p>}
          {description && <p className="text">{description}</p>}
        </div>
      )}
    </div>
  )
}

export default Slide
