import { Button, Form, Input, message } from 'antd'
import axios from 'axios'

import React from 'react'
import { useNavigate } from 'react-router-dom'

const LoginFrom = ({ isRegister = false }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const url = isRegister ? 'auth/register' : 'auth/authentication'
  const onFinish = async (values) => {
    try {
      const response = await axios.post(url, values)
      const token = response.data.data.token

      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] =`Bearer ${token}`
      navigate('/admin')
      message.success('Вход выполнен.')
    } catch (error) {
      message.error('Вход не выполнен. Проверьте email и пароль')
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 10 }}>
        <Form
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Это поле обязательно!',
              },
            ]}
          >
            <Input placeholder="email" />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Это поле обязательно!',
              },
            ]}
          >
            <Input.Password placeholder="password" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
              Вход
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default LoginFrom
