import { InboxOutlined } from '@ant-design/icons'
import { Button, Flex, Form, Input, InputNumber, Select, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useFiltersDataHook } from '../../../../hooks/useFiltersDataHook'

const { TextArea } = Input

const ProductForm = ({ product }) => {
  const { filterData, fetchFilter } = useFiltersDataHook()
  const navigate = useNavigate()
  const [imageList, setImageList] = useState([])
  const [smallimageList, setSmallimageList] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    fetchFilter()
  }, [])

  useEffect(() => {
    if (!product) return
    const values = {
      name: product.name,
      description: product.description,
      price: product.price,
      discount: product.discount,
      categoryId: product.category.id,
      materials: product.materials.map((it) => it.id),
      styles: product.styles.map((it) => it.id),
      purposes: product.purposes.map((it) => it.id),
    }

    const images = product.images.map((it) => ({ ...it, uid: it.id }))
    const smallImages = images.filter((it) => it.isSmall)
    const bigImages = images.filter((it) => !it.isSmall)

    setSmallimageList(smallImages)
    setImageList(bigImages)
    form.setFieldsValue(values)
  }, [product])

  const onFinish = async (values) => {
    if (!imageList.length || !smallimageList.length) {
      message.error('Добавьте изображения товара!')
      return
    }
    const formData = new FormData()
    formData.append('data', JSON.stringify(values))
    imageList.forEach((file) => {
      if (!file.id) {
        formData.append('files', file)
      }
    })

    smallimageList.forEach((file) => {
      if (!file.id) {
        formData.append('smallFiles', file)
      }
    })

    try {
      if (product) {
        const response = await axios.put(`/furniture/${product.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            )
            console.log(`Upload Progress: ${percentCompleted}%`)
          },
        })
        console.log('Server response:', response.data)
      } else {
        const response = await axios.post('/furniture', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            )
            console.log(`Upload Progress: ${percentCompleted}%`)
          },
        })

        console.log('Server response:', response.data)
        resetForm()
      }
      message.success('Данные товара сохранены успешно.')
      handleBack()
    } catch (error) {
      message.error('Ошибка загрузки')
      console.error('Error:', error)
    }
  }

  const resetForm = () => {
    form.resetFields()
    setImageList([])
    setSmallimageList([])
    // window.location.reload()
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const getSelectOptions = (list) => {
    return list.map((item) => ({ label: item.name, value: item.id }))
  }
  const removeImage = (imageId) => {
    if (!imageId) return
    try {
      axios.delete(`/furniture/image/${imageId}`)
    } catch (e) {
      console.error('remove error', e)
    }
  }

  const uploadProps = {
    onRemove: (file) => {
      if (product) {
        removeImage(file.id)
      }
      const index = imageList.indexOf(file)
      const newimageList = imageList.slice()
      newimageList.splice(index, 1)
      setImageList(newimageList)
    },
    beforeUpload: (file, files) => {
      setImageList([...imageList, ...files])
      return false // Prevent auto upload
    },

    multiple: true,
    accept: 'image/png, image/jpeg',

    fileList: imageList,
  }

  const uploadPreviewProps = {
    onRemove: (file) => {
      if (product) {
        removeImage(file.id)
      }
      const index = smallimageList.indexOf(file)
      const newimageList = smallimageList.slice()
      newimageList.splice(index, 1)
      setSmallimageList(newimageList)
    },
    beforeUpload: (file, files) => {
      // dont remove prev ones.
      const images = [...smallimageList, ...files].slice(-2)
      setSmallimageList(images)
      return false // Prevent auto upload
    },

    multiple: true,
    maxCount: 2,
    accept: 'image/png, image/jpeg',

    fileList: smallimageList,
  }

  const handleBack = () => {
    navigate('/admin')
  }

  const title = product ? 'Редактирование Товара' : 'Добавление Товара'

  return (
    <Form
      form={form}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      style={{
        maxWidth: 1400,
        padding: 80,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <h1
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {title}
      </h1>

      <Form.Item
        label="Название"
        name="name"
        rules={[
          {
            required: true,
            message: 'Обязательное поле!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Цена"
        name="price"
        rules={[
          {
            required: true,
            message: 'Обязательное поле!',
          },
        ]}
      >
        <InputNumber addonAfter="c" />
      </Form.Item>
      <Form.Item
        label="Скидка"
        name="discount"
      >
        <InputNumber
          min={0}
          max={99}
          addonAfter="%"
        />
      </Form.Item>

      <Form.Item
        label="Материалы"
        name="materials"
      >
        <Select
          showSearch
          optionFilterProp="label"
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Выберите материал"
          options={getSelectOptions(filterData.materials)}
        />
      </Form.Item>
      <Form.Item
        label="Категория"
        name="categoryId"
        rules={[
          {
            required: true,
            message: 'Обязательное поле!',
          },
        ]}
      >
        <Select
          showSearch
          optionFilterProp="label"
          allowClear
          style={{ width: '100%' }}
          placeholder="Выберите категорию"
          options={getSelectOptions(filterData.categories)}
        />
      </Form.Item>
      <Form.Item
        label="Стили"
        name="styles"
      >
        <Select
          showSearch
          optionFilterProp="label"
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Выберите стиль"
          options={getSelectOptions(filterData.styles)}
        />
      </Form.Item>

      <Form.Item
        label="Назначение"
        name="purposes"
      >
        <Select
          showSearch
          optionFilterProp="label"
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Выберите назначение"
          options={getSelectOptions(filterData.purposes)}
        />
      </Form.Item>

      <Form.Item
        label="Описание"
        name="description"
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item label="* Основные картинки товара">
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Кликните или перетащить файлы в эту область для загрузки.
          </p>
          <p className="ant-upload-hint">
            Поддерживаемые файлы только JPEG, PNG формата.
          </p>
        </Dragger>
      </Form.Item>

      <Form.Item label="* Превью картинки товара">
        <Dragger {...uploadPreviewProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Кликните или перетащить файлы в эту область для загрузки.
          </p>
          <p className="ant-upload-hint">
            Поддерживаемые файлы только JPEG, PNG формата. Не более 2-х картинок
          </p>
        </Dragger>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Flex gap="small">
          <Button
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
          <Button onClick={handleBack}>Отмена</Button>
        </Flex>
      </Form.Item>
    </Form>
  )
}
export default ProductForm
