import { Slider } from 'antd'

import React from 'react'

import { MAX_PRICE, MIN_PRICE } from '../../constants/global'

import './style.scss'
import { track } from 'framer-motion/client'

const PriceFilter = ({ value, setValue }) => {
  return (
    <div className="price-inputs">
      <div className="price-input-row">
        <Slider
          range={{
            draggableTrack: true,
          }}
          className="price-slider"
          min={MIN_PRICE}
          max={MAX_PRICE}
          value={value}
          onChange={setValue}
          step={500}
        />
      </div>
    </div>
  )
}

export default PriceFilter
