import axios from 'axios'
import { useRecoilState } from 'recoil'

import react, { useState } from 'react'

import { filtersDataState } from '../store/atoms'

export const useFiltersDataHook = () => {
  const [filterLoading, setFilterLoading] = useState(false)
  const [filterData, setFilterData] = useRecoilState(filtersDataState)

  const fetchCategories = async () => {
    const response = await axios.get('categories')
    return response.data
  }

  const fetchMaterials = async () => {
    const response = await axios.get('materials')
    return response.data
  }
  const fetchStyles = async () => {
    const response = await axios.get('styles')
    return response.data
  }

  const fetchPurposes = async () => {
    const response = await axios.get('purposes')
    return response.data
  }

  const fetchFilter = async () => {
    try {
      setFilterLoading(true)
      const categories = await fetchCategories()
      const materials = await fetchMaterials()
      const purposes = await fetchPurposes()
      const styles = await fetchStyles()

      setFilterData({
        categories,
        materials,
        purposes,
        styles,
      })
    } catch (e) {
      console.error('filter fetching error', e)
    } finally {
      setFilterLoading(false)
    }
  }

  return {
    fetchFilter,
    filterData,
    filterLoading,
    fetchCategories,
    fetchMaterials,
    fetchStyles,
    fetchPurposes,
  }
}
