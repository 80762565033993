import { Flex } from 'antd'
import axios from 'axios'

import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import LoadingSpinner from '../../../../components/loading-spinner'

import PurposeForm from './Form'

const EditPurpose = () => {
  const { id } = useParams()
  const [details, setDetails] = useState(null)
  const [isLoading, setIsloading] = useState(false)

  const fetchDetails = async (id) => {
    setIsloading(true)
    const response = await axios.get(`/purposes/${id}`)
    setDetails(response.data)
    setIsloading(false)
  }

  useEffect(() => {
    fetchDetails(id)
  }, [id])

  return isLoading ? (
    <Flex
      style={{ minHeight: '300px' }}
      align="center"
      justify={'center'}
    >
      <LoadingSpinner />
    </Flex>
  ) : (
    !isLoading && details && <PurposeForm data={details} />
  )
}

export default EditPurpose
