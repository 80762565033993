import { Tag, Typography } from 'antd'

import React from 'react'

import './style.scss'

const ProductTags = ({ tags = [], title, selected = [], setSelected }) => {
  return (
    <div className="tags-container">
      <Typography.Text className="tag-title">
        {title}
      </Typography.Text>
      <div className="tags">
        {tags.map((tag) => {
          const checked = selected.includes(tag.id)
          const className = checked ? 'tag active' : 'tag'
          return (
            <Tag.CheckableTag
              key={tag.id}
              className={className}
              checked={checked}
              onChange={() => setSelected(tag.id)}
            >
              {tag.name}
            </Tag.CheckableTag>
          )
        })}
      </div>
    </div>
  )
}

export default ProductTags
