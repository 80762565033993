import React from 'react'
import { Radio } from 'antd'

const RadioFilter = ({ selected, setSelected, options }) => {
  return (
    <Radio.Group 
      value={selected}
      onChange={setSelected}
      style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
    >
      {options.map((option) => (
        <Radio
          value={option.id}
          key={option.id}
        >
          <span>{option.name}</span>
        </Radio>
      ))}
    </Radio.Group>
  )
}

export default RadioFilter
