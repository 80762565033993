import { motion } from 'framer-motion'

import React from 'react'

import furnitureImage from '../../assets/images/furniture.jpeg'
import ButtonCatalog from '../button-catalog'

import './style.scss'

const FurnitureCard = ({
  title = 'Прикроватные тумбы',
  buttonText = 'Перейти в каталог',
  image = furnitureImage,
  onButtonClick,
  isExpanded,
  nav = false,
  backgroundColor,
}) => {
  return (
    <motion.div
      onClick={onButtonClick}
      className="furniture-card"
      style={{
        backgroundImage: `url(${image})`,
        backgroundColor: backgroundColor,
        backgroundBlendMode: 'multiply',
      }}
      animate={{
        backgroundColor: isExpanded ? backgroundColor : 'rgba(255, 255, 255, 0)',
      }}
      transition={{ duration: 0.5 }}
    >
      <div className="content">
        <h2 className="title">{title}</h2>
        <ButtonCatalog
          nav={nav}
          buttonText={buttonText}
          buttonClassName={"furniture-card-button"}
          onButtonClick={onButtonClick}
        />
      </div>
    </motion.div>
  )
}

export default FurnitureCard
