import React, { useEffect, useRef, useState } from 'react'

import CustomButton from '../button-custom'

import './style.scss'

const RecommendationCard = ({ data, imagePosition = 'left' }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [imageHeight, setImageHeight] = useState()
  const contentRef = useRef(null)

  const visibleCount = 4

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    if (contentRef.current) {
      setImageHeight(isExpanded ? contentRef.current.scrollHeight : 500)
    }
  }, [isExpanded])

  const visibleContent = isExpanded
    ? data.content
    : data.content.slice(0, visibleCount)

  const oddContent = visibleContent.filter((item) => item.id % 2 !== 0)
  const evenContent = visibleContent.filter((item) => item.id % 2 === 0)

  return (
    <div className="recommendation-card">
      <div
        className={`content-wrapper ${imagePosition === 'right' ? 'reverse' : ''}`}
      >
        <div
          className="recommendation-image"
          style={{
            height: `${imageHeight}px`,
            transition: 'height 0.3s ease',
          }}
        >
          <img
            src={data.image}
            alt="image"
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </div>

        <div
          className="recommendation-text"
          ref={contentRef}
        >
          <h2 className="title">{data.title}</h2>
          <div className="card-columns">
            <div className="column">
              {oddContent.map((item) => (
                <div
                  key={item.id}
                  className="recommendation-card-item"
                >
                  <p className="text">{item.text}</p>
                </div>
              ))}
            </div>
            <div className="column">
              {evenContent.map((item) => (
                <div
                  key={item.id}
                  className="recommendation-card-item"
                >
                  <p className="text">{item.text}</p>
                </div>
              ))}
            </div>
          </div>

          {data.content.length > visibleCount && (
            <div className={`button-wrapper ${isExpanded ? 'expanded' : ''}`}>
              <CustomButton
                type="primary"
                onClick={toggleExpand}
                text={isExpanded ? 'Свернуть' : 'Развернуть'}
                className="toggle-button"
                showArrow={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default RecommendationCard
